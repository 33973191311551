import dynamic from 'next/dynamic'
import type { FC } from 'react'

import { GlobeIconStyled } from '../../styles'

const ClassicView = dynamic(() => import('../ClassicView'), { ssr: false })

type Props = {
  isActive: boolean
  variant?: 'classic' | 'default'
}

export const MenuContainer: FC<Props> = ({ isActive, variant }) => {
  return variant === 'classic' ? <ClassicView isActive={isActive} /> : <GlobeIconStyled />
}
